.FormWrapper {
  max-width: 400px;
  margin: 25vh auto !important;
  background: $white;
}

.EmailConfirmFormWrapper {
  display: flex;
  flex-flow: wrap row;
  max-width: 50%;
  width: 100%;
  margin: 7% auto !important;
  padding: 42px 24px 50px !important;
  background: $white;
  justify-content: space-around;

  input {
    text-align: center;
  }
}
