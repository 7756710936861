body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#root {
  position: relative;
  width: 100%;
  height: 100%;
}
#root > section {
  position: relative;
  width: 100%;
  height: 100%;
}
.login-graphic {
  height: 100%;
  width: 100%;
  padding-top: 10vh;
}
h1 {
  font-size: 24px;
  font-family: lato;
  font-weight: bold;
}
h2 {
  font-size: 20px;
  font-family: lato;
  font-weight: bold;
}
h5 {
  font-size: 18px;
  font-family: lato;
  font-weight: bold;
}
.ant-form-item-label > label {
  font-weight: bold;
}
.ant-input,
.ant-picker,
.ant-form-item-label,
.ant-form-item-label > label,
.ant-form-item-control-input,
.ant-select .ant-select-selector {
  height: 40px;
}
.ant-btn-primary {
  background-color: #007969;
  border: none;
  border-radius: 0;
  height: 40px;
  font-size: 18px;
  font-family: lato;
}
.ant-btn-primary:hover {
  background-color: #2d393b;
}

.ant-btn-secondary {
  background-color: #ffb500;
  border: none;
  border-radius: 0;
  height: 40px;
  font-size: 18px;
  font-family: lato;
  color: rgba(255, 255, 255, 1);
}
.ant-btn-secondary:hover {
  background-color: #ca9100;
  color: rgba(255, 255, 255, 1);
}

.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}
.anticon {
  font-size: 18px !important;
}
.ant-input,
.ant-picker {
  border: none;
}

.ant-cascader-menu-item-active {
  background-color: #ffffe6;
}

.ant-picker-range .ant-picker-active-bar {
  background: #ffb500;
}

.ant-input:focus,
.ant-picker:focus,
.ant-picker-focused {
  border: 1px Solid #ffb500;
  border-color: #ffb500 !important;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  -moz-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  background: white;
}
.ant-input:hover,
.ant-picker:hover {
  background: white;
  border: 1px Solid #ffb500;
  border-color: #ffb500 !important;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: #ffb500;
  border-color: #ffb500;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #ffb500;
  border-color: #d89b00;
  color: #ffffe6;
}

.ant-radio-button-wrapper:hover {
  color: #ffb500;
}

.ant-layout {
  background: #fbfbfd;
}
.ant-layout-sider {
  border-right: 2px solid #eaedf3;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}
.register-form {
  margin-top: 20vh !important;
}
.main-select .content {
  padding: 20px;
}
