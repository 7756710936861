.CardWrapper {
  height: 100vh;
  padding: 20% 50px;
  background: $color-primary;
  color: $white;
}

.ant-card-head-title {
  font-size: 1.25rem;
}

.ant-card-body {
  p {
    font-size: 1rem;
    margin: 0;
  }
  .ant-tag {
    height: 30px;
    border-radius: 100px;
    color: white;
    font-size: 16px;
    padding: 5px 10px;
  }
  .ant-tag-red {
    background-color: $red;
    border-color: $red;
  }
  .ant-tag-green {
    background-color: $green;
    border-color: $green;
  }
  .ant-tag-yellow {
    background-color: $yellow;
    border-color: $yellow;
  }
}

.ant-card-bordered {
  border-color: $color-primary;
  margin-top: 30px;
  border-radius: 4px;
}
