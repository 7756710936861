/* Temp CSS until theming can be handled. */
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif !important;
}

.back-menu {
  position: absolute !important;
  top: 10px;
  left: 10px;
  z-index: 999;
}

.login-logo {
  width: 80%;
}

.header a {
  color: #2d393b !important;
  font-weight: 500 !important;
}

p.test-description {
  color: #2d393b !important;
  font-weight: 400 !important;
  margin-top: 0.5rem !important;
  overflow-wrap:break-word;
}

.card .ui.grid {
  align-items: center;
}

.card .icon-container a {
  padding-right: 20px;
}

.card .icon-container .green {
  padding-right: 35px;
  /* margin-right: 0; */
}
.grey.icon.check.button {
  padding-right: 38px;
}
.grey.icon.file.button {
  /* padding-right: 38px; */
}

.ui.button.white {
  color: rgba(255, 255, 255, 0.6) !important;
  background: transparent none !important;
  font-weight: 400 !important;
  border-radius: 0.28571429rem !important;
  text-transform: none !important;
  text-shadow: none !important;
  box-shadow: 0 0 0 1px rgb(255 255 255 / 35%) inset !important;
}

.cards .ui.button {
  font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
}

.ui.cards > .ui.button.buttonTag {
  text-transform: none;
}

.cards .header {
  font-size: 1.3em;
  font-weight: 600;
}

.ui.cards > .red.card,
.ui.red.card,
.ui.red.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 3px 0 #d4d4d5 !important;
  border-left: 10px solid #c24734;
}

.ui.cards > .green.card,
.ui.green.card,
.ui.green.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 3px 0 #d4d4d5 !important;
  border-left: 10px solid #34c28e;
}

.ui.cards > .yellow.card,
.ui.yellow.card,
.ui.yellow.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 3px 0 #d4d4d5 !important;
  border-left: 10px solid #f9b131;
}

.ui.cards > .grey.card,
.ui.grey.card,
.ui.grey.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 1px 3px 0 #d4d4d5 !important;
  border-left: 10px solid #767676;
}

.green-text {
  color: #34c28e !important;
}

.red-text,
.ui.cards i.red.icon {
  color: #c24734 !important;
}

.ui.primary.button:not(.basic) {
  background-color: #34afc2 !important;
  font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  /* min-width: 200px; */
}

.ui.green.button:not(.basic) {
  background-color: #008071 !important;
}

.ui.red.button:not(.basic) {
  background-color: #b71f26 !important;
}

.ui.teal.button,
.ui.teal.buttons .button,
.ui.basic.teal.button.back-menu {
  background-color: #34afc2 !important;
  color: #fff !important;
}

.ui.light-blue.button,
.ui.light-blue.buttons .button {
  background-color: #79c7d4 !important;
  color: #fff !important;
}

.ui.dark-blue.button,
.ui.dark-blue.buttons .button {
  background-color: #114f82 !important;
  color: #fff !important;
}

.ui.royal-blue.button,
.ui.royal-blue.buttons .button {
  background-color: #1852AB !important;
  color: #fff !important;
}

.ui.red.message:not(.basic) {
  background-color: #f9d0d1 !important;
  color: #c24734 !important;
  box-shadow: 0 0 0 1px #c24734 inset, 0 0 0 0 transparent !important;
}

.ui.red.message .header {
  color: #c24734 !important;
}

.ui.green.message {
  background-color: #dff4eb !important;
  color: #34c28e !important;
  box-shadow: 0 0 0 1px #34c28e inset, 0 0 0 0 transparent !important;
}

.ui.green.message .header {
  color: #34c28e !important;
}

.ui.icon.message.small > .icon:not(.close) {
  font-size: 15px;
}

#testGrid .ui.selection.dropdown {
  margin-bottom: 1rem;
}

#testGrid .ui.input {
  height: 2.71428571em;
  width: 16em;
}

@media (max-width: 768px) {
  .card .ui.column.grid > [class*='seven wide'].column,
  .ui.grid > .column.row > [class*='seven wide'].column,
  .ui.grid > .row > [class*='seven wide'].column,
  .ui.grid > [class*='seven wide'].column {
    width: 100% !important;
  }
  .card .ui.column.grid > [class*='six wide'].column,
  .ui.grid > .column.row > [class*='six wide'].column,
  .ui.grid > .row > [class*='six wide'].column,
  .ui.grid > [class*='six wide'].column {
    width: 100% !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
  .card .ui.column.grid > [class*='two wide'].column,
  .ui.grid > .column.row > [class*='two wide'].column,
  .ui.grid > .row > [class*='two wide'].column,
  .ui.grid > [class*='two wide'].column {
    width: 50% !important;
  }
  .card .icon-container a {
    padding-left: 25px;
    padding-right: 0;
  }
}
