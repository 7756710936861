.add-new-step-btn {
  margin-top: 15px;
}

#testForm .cron_builder {
  width: 100%;
}
.tab-content,
.tab-pane {
  padding: 0 2rem;
  text-align: left;
}
ul.nav.nav-tabs li {
  list-style: none;
  margin: 0.25rem;
  display: inline;
}
.cron_builder .tab_pane {
  text-align: left;
}
.cron_builder_bordering .well {
  display: flex;
  align-items: center;
}
.ui.form .cron_builder .row {
  display: flex;
  align-items: center;
}
.ui.form .cron_builder input[type='number'],
.ui.form .cron_builder .tab-content select {
  width: 6rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.ui.form .cron_builder .well-small input,
.ui.form .cron_builder .well-small input[type='text'] {
  width: 6rem !important;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.ui.form .cron_builder .well-small input[type='radio'] {
  margin-left: 0;
  width: unset !important;
}
.ui.form .cron_builder select {
  width: 6rem !important;
  display: inline;
}

.ui.form .cron_builder .well-small {
  margin-bottom: 1rem;
}
