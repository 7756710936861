$black: #333333;
$white: #ffffff;
$color-primary: #007969;
$secondary: #ffb500;
$grey: #ebedf0;
$green: #007969;
$blue: #0a84ff;
$red: #ff543e;
$yellow: #ffb500;
$softGrey: #dddddd;
$softBlack: #2d393b;
$fontBlack: #3e3f42;
$transparentBlack: rgba(0, 0, 0, 0.25);

$transitions-primary: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
$shadow-depth-1: 0 2px 4px rgba(0, 0, 0, 0.1);
$shadow-depth-2: 0 5px 10px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1);
$shadow-depth-3: 0 10px 20px rgba(0, 0, 0, 0.1), 0 20px 40px rgba(0, 0, 0, 0.1);
$shadow-depth-4: 0 10px 30px rgba(0, 0, 0, 0.1), 0 30px 60px rgba(0, 0, 0, 0.1);
$shadow-depth-5: 0 20px 40px rgba(0, 0, 0, 0.1), 0 40px 80px rgba(0, 0, 0, 0.1);

$responsive-large: 77.5em; /* (1240px) */
$responsive-medium: 60em; /* (960px) */
$responsive-small: 40em; /* (640px) */
