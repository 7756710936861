.ant-layout-sider-trigger {
  background: #007969 !important;
}

.ant-layout-sider-children {
  background-color: white;
}

.ant-menu-root.ant-menu-inline {
  height: 100%;
}

.side-bar {
  max-width: 400px;
}

.ant-layout-header {
  height: 110px;
}

.ant-menu-vertical .ant-menu-item {
  margin-top: 0;
}

.sider-collapsed {
  .ant-menu-item {
    height: 60px;
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    font-family: lato;
    color: #3e3f42;
  }
}

.ant-layout-sider-collapsed {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;

  .ant-layout-sider-trigger {
    width: 100px !important;
  }

  .main-select {
    .ant-menu-item-selected {
      width: 60px;
    }
  }
}

.main-select {
  .ant-menu-item:hover {
    color: #007969;
  }
  .ant-menu-item-selected {
    border-radius: 100px;
    width: 90%;
    border: 2px solid #359487;
  }
  ul li {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .ant-menu-item {
    height: 60px !important;
    padding: 10px 20px !important;
    margin: 0 20px;
    font-size: 18px;
    font-weight: bold;
    font-family: lato;
    color: #3e3f42;
  }
  .ant-menu:not(.ant-menu-horizontal),
  .ant-menu-item-selected {
    background-color: #fafbfc !important;
    color: $color-primary;
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none !important;
}

.bottom-select {
  bottom: 50px;
  position: absolute;
  width: 100%;
  border-right: none;
  padding: 20px 0 !important;

  ul li {
    margin: 20px;
    font-size: 16px;
    font-weight: bold;

    &:hover {
      color: $color-primary;
    }
  }
  .ant-menu-item-selected {
    background-color: #fafbfc !important ;
  }
  .ant-menu-item {
    padding: 10px 20px !important;
    margin: 0 20px;
  }
}

.ant-menu-item-selected {
  background-color: #fafbfc;
}

::selection {
  color: #007969;
  background: #fafbfc;
}
.bottom-select ul .ant-menu-item-selected {
  color: #007969;
  background-color: #fff;
  width: 90%;
  border: 0;
}
.bottom-select {
  ul {
    .ant-menu-item {
      height: 50px;
      padding: 0px 20px;
    }
  }
}

.property-select {
  height: 90vh;
  overflow: hidden;
  ul li {
    padding: 30px 0;
    margin: 0;
  }
  .ant-menu-item {
    height: 90px !important;
    padding: 30px !important;
    font-size: 18px;
    font-weight: bold;
    color: #8d8d8d;

    &:hover {
      color: $color-primary;
    }
  }

  .ant-menu-item-selected {
    background-color: transparent !important;
    color: $color-primary !important;
    border-left: 3px solid $color-primary;
    z-index: 199;
  }
}
