.LoginBackground {
  height: 100vh;
  overflow: hidden;
  top: 0;
  position: absolute;
  width: 100vw;
  background: $color-primary;
}

.LoginLeftSection {
  height: 100vh;
  top: 0;
  position: absolute;
  left: 0;
  width: 50vw;
  background-repeat: repeat;
  background-color: $color-primary;
}

.LoginRightSection {
  @media (max-width: 768px) {
    width: 100vw !important;
    padding: 20px;
    form.register-form {
      margin-top: 0 !important;
    }
  }
  height: 100vh;
  top: 0;
  right: 0;
  position: absolute;
  width: 50vw;
  background: $white;
}
